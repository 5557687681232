import React from "react";
import PaperUpload from '../PaperUpload/PaperUpload';
import PaperCollection from '../PaperCollection/PaperCollection';
import './Landing.css';
import { Container, Row, Col } from "react-bootstrap";

function Landing() {
    return (
        <Container fluid style={{height: 100+"vh", padding: 0}}>
            <Row style={{height: 100+"%"}}>
                <Col md={2} style={{padding: 0}}>
                    <PaperUpload />
                </Col>
                <Col md={10} style={{padding: 0}}>
                    <PaperCollection />
                </Col>
            </Row>
        </Container>
    )
}

export default Landing;