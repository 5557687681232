import React, { useState } from 'react';
import sha256 from 'crypto-js/sha256';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function PaperUpload() {

    const [password, setPassword] = useState('')
    const [file, setFile] = useState()
    const [result, setResult] = useState(null);

    const retrievePassword = (event) => {
        setPassword(event.target.value);
        event.preventDefault();
    }

    const retrieveFile = (event) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    }

    const submit = async (event) => {
        event.preventDefault();

        if (!file) return;
        if (!password) return;

        const data = new FormData();
        data.append("file", file);
        fetch("https://proseminar.pewtech.de/proseminar", {
            method: "POST",
            body: data,
            headers: {
                "supersecret": sha256(password).toString()
            }
            //Web3RWTHSS2024SuperSecretKey
        }).then(async response => {
            document.getElementById("upload-pdf-form").reset()
            const data = await response.json();
            console.log(data)
            setResult(data);
        })

        console.log(`The password is: ${sha256(password)} and the file is: ${file}`);
    }

    return (
        <div className='uploadSection'>
            <h3>Upload your paper here</h3>
            <Form id={"upload-pdf-form"}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={retrievePassword}/>
                    <Form.Text className="text-muted">
                        The password for uploading was given by my presentation in one of the seminars.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicFile">
                    <Form.Label>Paper PDF</Form.Label>
                    <Form.Control type="file" name="file" onChange={retrieveFile} />
                    <Form.Text className="text-muted">
                        Only PDFs are allowed.
                    </Form.Text>
                </Form.Group>
                <Button variant="primary" onClick={submit}>
                    Distribute now!
                </Button>
                {
                    result != null ? 
                    <Form.Group className='mb-3'>
                        <Form.Text className='uploadText' style={result.cid ? {color: "white"} : {color: "red"}}>
                            {
                                result.cid
                                ? "Your file was distributed to IPFS!"
                                : `Error: ${result?.error}`
                            }
                        </Form.Text>
                    </Form.Group>

                    : null
                }
            </Form>
        </div>
    );
}

export default PaperUpload;
