import './App.css';
import React from 'react';
import Landing from './Landing/Landing';
import { Routes, Route } from 'react-router-dom';
import PaperReader from './PaperReader/PaperReader';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/:cid" element={<PaperReader />} />
    </Routes>
    
  );
}

export default App;
