import React, {useState, useEffect}  from "react";
import { useParams,  } from 'react-router-dom'
import './PaperReader.css';

function PaperReader() {

    const { cid } = useParams();
    const [paper, setPaper] = useState(null);

    useEffect(() => {
        setPaper(`https://${cid}.ipfs.w3s.link/`)
    }, [cid]);

    return (
        <div className="PaperReader">
            <iframe className="Reader" title="PaperReader" src={paper}></iframe>
        </div>
    );
}

export default PaperReader;