import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
// test
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <header className='header' md={12}>
      <h1>Proseminar Web3 SS 2024</h1>
      <p>This Website was created to demonstrate IPFS and Web3 Applications due the Proseminar for Web3.</p>
    </header>
    <App />
  </BrowserRouter>
);
