import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf';
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PaperCollection() {

    const [paperCollection, setPaperCollection] = useState([]);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        retrievePapers();
    }, []);

    function onDocumentLoadSuccess() {
        setNumPages(numPages);
    }

    const retrievePapers = () => {
        fetch("https://proseminar.pewtech.de/proseminar", {
            method: "GET"
        }).then(response => response.json())
            .then(data => setPaperCollection(data))
            .catch(error => console.log(error));
    }

    return (
        <Container fluid className="paperCollection">
            <Row className={"header"} md={12}>
                <h2>Collection of papers</h2>
                <p>Here you can find all papers that were uploaded and created due this Proseminar.</p>
            </Row>
            <Row className='collectionContainer g-4' xs={1} md="auto">
                {paperCollection.map((paper, index) => (
                    <Col className={"paper-item"} key={index}>
                        <Card style={{width: "18rem"}}>
                            <Card.Header variant="top">
                                <Document 
                                    file={`https://${paper.cid}.ipfs.w3s.link/`} 
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    noData={"Something went wrong with the paper."}
                                    loading={"Loading paper..."}
                                >
                                    <Page pageNumber={1} height={300}/>
                                </Document>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>{paper.filename}</Card.Title>
                                <ListGroup variant="flush">
                                    <Link to={`/${paper.cid}`}>
                                        <Button style={{width: "100%"}} variant="primary">Read paper</Button>
                                    </Link>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default PaperCollection;